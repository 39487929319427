















































































































import Vue from 'vue';
import { User } from '@/models/user.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { db } from '@/main';
import { Place } from '@/models/place.model';

export default Vue.extend({
  name: 'Profile',
  components: {
    PlaceSearch: () => import('@/components/PlaceSearch.vue')
  },
  data() {
    return {
      options: {
        apiKey: process.env.VUE_APP_PLACES_API_KEY
      },
      isBusy: false,
      showVerifyEmailButton: false,
      user: {} as User,
      rules: {
        required: (value: string) => !!value || 'Required.',
        min: (value: string) => value.length >= 6 || 'Min 6 characters',
        email: (value: string) =>
          /.+@.+\..+/.test(value) || 'E-mail must be valid',
        mobile: (value: string) =>
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
            value
          ) || 'Mobile number must be valid',
        year: (value: string) =>
          value?.length === 4 || 'Please enter a four digit year'
      },
      isUserDetailsFormValid: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      place: {} as Place,
      placeLoading: false,
      originalUser: {} as User,
      showPasswordField: false,
      password: ''
    };
  },
  methods: {
    getData() {
      db.collection('users')
        .doc(this.$store.state.currentUser.uid)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.user = doc.data() as User;
          console.log(this.user);

          this.originalUser = { ...this.user };
          this.place = {
            address: this.user.address,
            city: this.user.city,
            country: this.user.country,
            googlePlaceId: this.user.googlePlaceId,
            geoPoint: this.user.geoPoint
          } as Place;
        });
    },
    verifyEmail() {
      const authUser = firebase.auth().currentUser;
      authUser
        ?.updateEmail(this.user.email)
        .then(() => {
          authUser
            .sendEmailVerification()
            .then(() => {
              this.$confirm(
                `Please verify your new email: ${this.user.email}.`,
                {
                  buttonTrueText: 'I have verified my email',
                  buttonFalseText: 'Cancel'
                }
              ).then(res => {
                if (res) {
                  this.hasVerifiedEmailCheck();
                }
              });
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
    },
    updateData() {
      db.collection('users')
        .doc(this.$store.state.currentUser.uid)
        .set(this.user, { merge: true })
        .then(() => {
          this.showSnackbar('User updated.', 'success');
          this.originalUser = { ...this.user };
        })
        .catch((error: Error) => {
          this.showSnackbar(error.message, 'error');
          console.error(error);
        });
    },
    reauthenticateUser() {
      if (this.originalUser.email !== this.user.email) {
        this.isBusy = true;
        const user = firebase.auth().currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(
          this.originalUser.email,
          this.password
        );
        user
          ?.reauthenticateWithCredential(credentials)
          .then(() => {
            this.verifyEmail();
          })
          .catch(error => {
            this.isBusy = false;
            this.$confirm(error.message, {
              buttonTrueText: 'Ok',
              buttonFalseText: 'Cancel'
            });
            console.error(error);
          });
      } else {
        this.updateData();
      }
    },
    async hasVerifiedEmailCheck() {
      this.isBusy = true;
      const user = firebase.auth().currentUser;
      await user?.reload();
      if (user?.emailVerified) {
        this.isBusy = false;
        this.showSnackbar('Email verified successfully.', 'success');
        this.updateData();
      } else {
        this.isBusy = false;
        this.showVerifyEmailButton = true;
        this.showSnackbar(
          'Email not verified successfully please try again.',
          'error'
        );
      }
    },
    placeSelected(place: Place) {
      this.user.googlePlaceId = place.googlePlaceId;
      this.user.address = place.address;
      this.user.city = place.city;
      this.user.country = place.country;
      this.user.geoPoint = place.geoPoint;
    },
    onMobileNumberInput(formattedNumber, { number }) {
      this.user.mobileNumber = number.international;
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  watch: {
    'user.email': function() {
      if (this.originalUser.email !== this.user.email) {
        this.showPasswordField = true;
      } else {
        this.showPasswordField = false;
      }
    }
  },
  created() {
    this.getData();
  },
  beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.originalUser) !== JSON.stringify(this.user)) {
      this.updateData();
      setTimeout(() => {
        next();
      }, 1000);
    } else {
      next();
    }
  }
});
